import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {},
  props: {},
})
export default class ListContentWrapperMixin extends Vue {
  movePage(item) {
    window.location.href = item.caption;
  }
  updateRoutes(item) {
    const routes = [];
    for (let current = item; current; current = current.parent) {
      routes.unshift(current);
    }
    routes.push.apply(routes, {});
    this.$store.commit('navigation/setRoutes', routes);
    this.$store.commit('navigation/setIndex', routes.length - 1);
  }
  updateTicket({ item, faqAction }) {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    const historyAction = { type: '', value: faqAction };
    this.$store.commit('ticket/addHistoryActionFaqChannel', historyAction);
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    }
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }
    return this.generateTicket(item, startTime, query, tagActiveSet);
  }
  generateTicket(item, startTime, query, tagActiveSet) {
    const commonTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      product_type: this.$store.state.productType,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
    };

    let newTicket = {
      ...commonTicket,
      status: item.viewType === 'result' ? 'answered' : 'answering',
      items: item.items,
    };
    if (item.viewType === 'result') {
      newTicket.status_feedback = 'open';
    }

    return newTicket;
  }
}
