import Vue from 'vue';
import Component from 'vue-class-component';
import dataResource from '@/common/dataResource';
import { AsyncComputed } from '@/libs/vue-async-computed-decorator';
import { eventBus } from '@/eventBus';

@Component({
  props: {
    currentValue: Object,
    isRoot: Boolean,
    condition: Function,
    showResult: Boolean,
    selectedCategories: Array,
  },
})
export default class ResourceTreeMixin extends Vue {
  get categoryTags() {
    return this.$store.state.tagSearch.selectedTags.values();
  }
  @AsyncComputed()
  async list() {
    if (this.condition(this.currentValue)) {
      return dataResource.getList(this.currentValue);
    }
  }
  async toggleItems(item) {
    if (this.isSelected(item)) {
      this.close(item);
    } else {
      await this.open(item);
    }
  }
  async open(item) {
    if (!this.isBigCategory(item)) {
      await this.$store.dispatch('tagSearch/resetInput');
      await this.$store.dispatch('tagSearch/addSelectedTag', {
        text: item.text,
        displayText: `${item.parent.text} > ${item.text}`,
        type: 'node',
        id: item.talkScriptId,
      });
      await this.setTagSearchShow();
      setTimeout(() => {
        eventBus.$emit('scrollToSearch');
      }, 0);
    }
    const categories = this.isBigCategory(item) ? [item] : [item.parent, item];
    eventBus.$emit('setSelectedCategories', categories);

    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = this.updateTicket(item);
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  updateTicket(item) {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    const historyAction = { type: '', value: 'category' };
    this.$store.commit('ticket/addHistoryActionFaqChannel', historyAction);
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    }
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }
    return this.generateTicket(item, startTime, query, tagActiveSet);
  }
  generateTicket(item, startTime, query, tagActiveSet) {
    const commonTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      product_type: this.$store.state.productType,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
    };

    let newTicket = {
      ...commonTicket,
      status: 'open',
      items: item.items,
    };

    return newTicket;
  }
  async close(item) {
    if (!this.isBigCategory(item)) {
      await this.$store.dispatch('tagSearch/removeTagByName', item.text);
    }
    eventBus.$emit('setSelectedCategories', []);
  }
  isActive(item) {
    if (item.talkScriptId === '#') {
      return false;
    }
    return (
      this.selectedCategories.filter(c => {
        return (
          c.talkScriptId === item.talkScriptId ||
          c.talkScriptId === item.talkScriptId
        );
      }).length > 0
    );
  }
  isSelected(item) {
    if (item.talkScriptId === '#' || this.selectedCategories.length === 0) {
      return false;
    }
    return (
      this.selectedCategories.filter(c => {
        return c.talkScriptId === item.talkScriptId;
      }).length > 0
    );
  }
  isBigCategory(item) {
    return item.parent && item.parent.talkScriptId === '#';
  }
  setTagSearchShow() {
    this.$emit('setTagSearchShow');
  }
}
