<template>
  <div class="FaqClientMultiple">
    <FaqClientPage v-if="!$store.state.user.isSP" ref="faqClient" />
    <FaqClientWindow v-if="$store.state.user.isSP" ref="faqClient" />
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {},
})
export default class FaqClientMultiple extends Vue {
  autoScrollElement = 'init';
  autoScroll() {
    if (this.$refs.faqClient.autoScrollElement) {
      this.$refs.faqClient.autoScroll();
    }
  }
}
</script>

<style lang="scss" scoped>
.FaqClientMultiple {
  height: 100%;
}
</style>
