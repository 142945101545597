export default {
  namespaced: true,
  state: {
    startTime: '',
    resetFlag: false,
    tagUsedSet: [],
    historyQuery: [],
    historyActionFaqChannel: [],
  },
  getterts: {},
  mutations: {
    setStartTime(state, payload) {
      state.startTime = payload;
    },
    setResetFlag(state, payload) {
      state.resetFlag = payload;
    },
    setTagUsedSet(state, payload) {
      state.tagUsedSet = payload;
    },
    setHistoryQuery(state, payload) {
      state.historyQuery = payload;
    },
    setHistoryActionFaqChannel(state, payload) {
      state.historyActionFaqChannel = payload;
    },
    addTagUsedSet(state, payload) {
      state.tagUsedSet.push(payload);
    },
    addHistoryQuery(state, payload) {
      state.historyQuery.push(payload);
    },
    addHistoryActionFaqChannel(state, payload) {
      state.historyActionFaqChannel.push(payload);
    },
  },
  actions: {
    addTagUsedSets({ commit }, tags) {
      for (let i in tags) {
        commit('addTagUsedSet', tags[i]);
      }
    },
    addHistoryActionFaqChannels({ commit }, histories) {
      for (let i in histories) {
        commit('addHistoryActionFaqChannel', histories[i]);
      }
    },
    resetTicketState({ commit }) {
      commit('setStartTime', '');
      commit('setResetFlag', false);
      commit('setTagUsedSet', []);
      commit('setHistoryQuery', []);
      commit('setHistoryActionFaqChannel', []);
    },
  },
};
