const parser = require('ua-parser-js');

let instance = null;
const getInstance = ua => {
  if (instance) {
    return instance;
  }
  instance = parser(ua);
  return instance;
};

const generateBrowser = ua => {
  const u = getInstance(ua);
  return u.browser;
};

const generateDevice = ua => {
  const u = getInstance(ua);
  return u.device;
};

const generateOs = ua => {
  const u = getInstance(ua);
  return u.os;
};

const getUa = ua => {
  return parser(ua);
};

export const uaParser = {
  getUa,
  generateBrowser,
  generateDevice,
  generateOs,
};
