import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import dataResource from '@/common/dataResource';
import { updateRelation, getSelectionPosition } from '@/common/relation';
import { AsyncComputed } from '@/libs/vue-async-computed-decorator';
import { scrollIntoViewY } from '@/common/scrollUtil';
import isEqual from 'lodash/isEqual';
import { eventBus } from '@/eventBus';
import textParser from '@/libs/textParser';

Vue.directive('scroll', {
  inserted: function(el, binding) {
    let f = function(evt) {
      if (binding.value(evt, el)) {
        // window.removeEventListener('scroll', f);
      }
    };
    el.addEventListener('scroll', f);
  },
});

@Component({
  props: {
    currentValue: Object,
    prevValue: Object,
    nextValue: Object,
    prevLocal: Object,
    nextLocal: Object,
    currentLocal: Object,
    index: Number,
    active: Boolean,
    enableScroll: Boolean,
  },
})
export default class ResourceListMixin extends Vue {
  feedbackWithEnquete = this.$store.state.constObj.WITH_ENQUETE_RESOLVED;
  feedbackWithSuggestion = this.$store.state.constObj.WITH_ENQUETE_UNRESOLVED;

  get firstIndex() {
    return this.index === 0;
  }
  get lastScript() {
    const talkScripts = this.routes.filter(r => {
      return r.talkScriptId;
    });
    return talkScripts[talkScripts.length - 1];
  }
  get scenarios() {
    const scenarios = this.routes.slice(0, this.index + 1).filter(r => {
      return !r.talkScriptId;
    });
    return scenarios;
  }
  get routes() {
    return this.$store.getters['navigation/getRoutes'];
  }
  @Watch('routes')
  onUpdateFirstPage() {
    if (!this.$store.state.user.isSP) {
      return;
    }
    if (this.routes.length === 1 && this.index === 0) {
      setTimeout(() => {
        this.scrollTop();
      }, 600);
    }
  }
  @AsyncComputed()
  async list() {
    const list = await dataResource.getList(this.currentValue);
    return list;
  }
  @AsyncComputed()
  async item() {
    const item = await dataResource.getItem(this.currentValue);
    return item;
  }
  @Watch('list')
  onUpdateList(list, oldList) {
    if (isEqual(list, oldList)) {
      return;
    }
    Vue.nextTick(() => {
      this.$refs.scrollGuide && this.$refs.scrollGuide.updateScrollGuide();
    });
  }
  @Watch('item')
  onUpdateItem(item, oldItem) {
    if (isEqual(item, oldItem)) {
      return;
    }
    Vue.nextTick(() => {
      this.$refs.scrollGuide && this.$refs.scrollGuide.updateScrollGuide();
    });
  }
  async openHandler(item) {
    if (item.talkScriptId && item.viewType === 'scenario') {
      const scenario = await dataResource.getItem(item);
      Object.assign(item.items, scenario.items);
    }
    this.open(item);
    if (
      item.talkScriptId === '#' ||
      (item.viewType === 'talkScript' && this.$store.state.ticket.resetFlag)
    ) {
      eventBus.$emit('resetTicket');
      return;
    }

    if (item.viewType !== 'talkScript') {
      this.$store.commit('ticket/setResetFlag', true);
    }
    const newTicket = this.updateTicket(item);
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  open(item) {
    this.$store.commit('navigation/open', {
      index: this.index,
      route: item,
    });
  }
  async verticalOpenHandler(item) {
    this.$store.commit('navigation/open', {
      index: this.index + this.talkScriptIndex,
      route: item,
    });

    this.$store.commit('ticket/setResetFlag', true);
    const newTicket = this.updateTicket(item);
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  get talkScriptIndex() {
    return (
      this.$store.getters['navigation/getRoutes'].filter(r => {
        return r.talkScriptId;
      }).length - 1
    );
  }
  updateTicket(item) {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    if (item.talkScriptId) {
      const historyAction = { type: '', value: 'category' };
      this.$store.commit('ticket/addHistoryActionFaqChannel', historyAction);
    }
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    }
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }
    return this.generateTicket(item, startTime, query, tagActiveSet);
  }
  generateTicket(item, startTime, query, tagActiveSet) {
    let ticketParams = { items: item.items };
    switch (item.viewType) {
      case 'talkScript':
        ticketParams.status = 'open';
        break;
      case 'scenario':
        ticketParams.status = 'answering';
        break;
      case 'result':
        ticketParams.status = 'answered';
        ticketParams.status_feedback = 'open';
        break;
    }
    const commonTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      product_type: this.$store.state.productType,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
    };

    return { ...commonTicket, ...ticketParams };
  }
  isSelected(item) {
    return dataResource.isEquals(item, this.nextValue);
  }
  onScroll(e) {
    if (
      e.target.scrollTop >= 0 &&
      e.target.clientHeight < this.$refs.scrollWrapper.clientHeight &&
      e.target.scrollTop <= e.target.scrollHeight - e.target.clientHeight
    ) {
      this.currentLocal.scrollPosition = e.target.scrollTop;
      this.updateRelation();
    }
  }
  @Watch('prevLocal.scrollPosition', { deep: true, immediate: true })
  onPrevLocalChange(scrollPosition) {
    this.updateRelation();
  }
  isTitle(caption) {
    return !(caption.match(/\r?\n/) || caption.match(/<br>|<br\/>/));
  }
  isBigCategory(item) {
    return item.parent && item.parent.talkScriptId === '#';
  }
  scrollBottom() {
    const element = '#sai-reset';
    const option = {
      container: '.pageScrollTarget',
      x: false,
      offset: this.$refs.scrollWrapper.clientHeight + 115,
    };
    this.$scrollTo(element, option);
  }
  scrollTop() {
    const element = '.scrollWrapper';
    const option = {
      container: '.pageScrollTarget',
      x: false,
      offset: -5,
    };
    this.$scrollTo(element, option);
  }
  cleanText(text) {
    return textParser.cleanText(text);
  }
  existsFaqNo(text) {
    return textParser.existsFaqNo(text);
  }
  generateFaqNo(text) {
    return textParser.generateFaqNo(text);
  }
  updated() {
    this.currentLocal.selectedPosition = getSelectionPosition({
      items: this.$refs.items,
    });
    if (this.enableScroll) {
      const selectedElement = this.$el.querySelector('.item.selected');
      if (selectedElement) {
        setTimeout(() => {
          scrollIntoViewY(selectedElement);
        }, 20);
      }
    }
    Vue.nextTick(() => {
      this.updateRelation();
    }, 0);
  }
  updateRelation() {
    updateRelation({
      root: this.$el,
      items: this.$refs.items,
      prevLocal: this.prevLocal,
      currentLocal: this.currentLocal,
      scrollAnchor: this.$refs.scrollAnchor,
      anchor: this.$refs.anchor,
      getRelation: item => item.children[0].children[0],
      disable: !this.list || !this.list.length,
    });
  }
}
