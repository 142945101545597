import scenarioApi from '../scenario';

console.log(scenarioApi);

(async () => {
  scenarioApi.ready();
})();

class ScenarioResource {
  cache = new WeakMap();
  ready() {
    return scenarioApi.ready();
  }
  async getList(params) {
    const scenario = await scenarioApi.get(params.scenarioId);
    let step = null;
    if (params.stepId) {
      step = scenario.steps[params.stepId];
    } else {
      step = scenario.rootStep;
    }
    return step.options.map(option =>
      this.convertOption({ scenario, step, option })
    );
  }
  isEquals(a, b) {
    return (
      a.scenarioId === b.scenarioId &&
      a.stepId === b.stepId &&
      a.talkScriptId === b.talkScriptId
    );
  }

  async getItem(params) {
    const scenario = await scenarioApi.get(params.scenarioId);
    if (params.stepId) {
      return this.convertStep({
        step: scenario.steps[params.stepId],
        scenario,
      });
    } else {
      return this.convertStep({ step: scenario.rootStep, scenario });
    }
  }
  convertStep = ({ step, scenario }) => {
    if (!step) {
      console.error('imcompatible scenario');
      return;
    }
    if (!this.cache.has(step)) {
      this.cache.set(step, {
        text: step.title,
        caption: step.text,
        scenarioId: scenario.scenarioId,
        stepId: step.id,
        componentName: 'ResourceList',
        resourceName: 'scenario',
        viewType: step.type === 'a' ? 'result' : 'scenario',
        items: step.items,
      });
    }
    return this.cache.get(step);
  };
  convertOption = ({ option, scenario }) => {
    if (!this.cache.has(option)) {
      const step = scenario.steps[option.stepId];
      this.cache.set(option, {
        // convertOptionでは、converStepと異なり、option側のテキストを使う（通常は同じ）
        text: option.condition.value,
        caption: step.text,
        scenarioId: scenario.scenarioId,
        stepId: step.id,
        componentName: 'ResourceList',
        resourceName: 'scenario',
        viewType: step.type === 'a' ? 'result' : 'scenario',
        items: step.items,
      });
    }
    return this.cache.get(option);
  };
}

export const scenarioResource = new ScenarioResource();

// window.scenarioResource = scenarioResource;
// window.scenarioApi = scenarioApi;
