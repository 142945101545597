import { createDecorator } from 'vue-class-component';

export function AsyncComputed(options) {
  return createDecorator((componentOptions, path) => {
    if (typeof componentOptions.asyncComputed !== 'object') {
      componentOptions.asyncComputed = Object.create(null);
    }
    componentOptions.asyncComputed[path] = Object.assign(
      {
        get: componentOptions.methods[path],
        debounce: false,
      },
      options
    );
    delete componentOptions.methods[path];
  });
}
