/* eslint-disable */

'use strict'
import tinySegmenter from './tinySegmenter' 

class BaseTagSearch {
  constructor(packageData) {
    this.packageData = packageData
    this.stopWords = [
      '　',
      ' ',
      'の',
      'が',
      'れ',
      'なっ',
      'しまっ',
      'すれ',
      'い',
      'なら',
      'どう',
      'せる',
      'する',
      'こと',
      'でき',
      'はいつ',
      'ため',
      '時',
      'なり',
      'かけ',
      'すぐ',
      'し',
      'まし',
      'たい',
      'ない',
      'いい',
      'する',
      'です',
      'できる',
      'いる',
      'した',
      'ある',
      'さ',
      'に',
      'た',
      'はどこか',
      '場合',
      'は',
      'と',
      'を',
      'て',
      'で',
      'も',
      'でも',
      '?',
      '？'
    ]
    try {
      this.sortedKeywordVariationsDict = Object.keys(
        this.packageData.synonymDict
      )
        .filter(k => {
          return (k && this.packageData.synonymDict[k])
        })
        .map(k => {
          return { key: k, value: this.packageData.synonymDict[k] }
        })
        .sort((a, b) => {
          return b.key.length - a.key.length
        })
    } catch (e) {
      console.log(e)
    }
  }
  search(query = ''){}
  createModel(sentences, labels){}
  saveModel(){}
  _sortScore(scores){
      return scores
        .filter((o, i, a) => {
          let max = 0
          let maxI = 0
          a.forEach((_o, _i, _a) => {
            if (o.id == _o.id) {
              max = Math.max(max, _o.weight)
              if (max <= _o.weight) {
                maxI = _i
              }
            }
          })
          return max == o.weight && maxI == i
        })
        .sort((a, b) => {
          if (a.weight > b.weight) {
            return -1
          } else if (a.weight == b.weight) {
            return 0
          }
          return 1
        })
  }
  _levenshteinDistance(str1 = '', str2 = '') {
    const x = str1.length
    const y = str2.length
    let d = []
    for (let i = 0; i <= x; i++) {
      d[i] = []
      d[i][0] = i
    }
    for (let i = 0; i <= y; i++) {
      d[0][i] = i
    }
    let cost = 0
    for (let i = 1; i <= x; i++) {
      for (let j = 1; j <= y; j++) {
        cost = str1[i - 1] == str2[j - 1] ? 0 : 1
        d[i][j] = Math.min(
          d[i - 1][j] + 1,
          d[i][j - 1] + 1,
          d[i - 1][j - 1] + cost
        )
      }
    }
    return d[x][y]
  }

  _hiraToKana(str) {
    return str.replace(/[\u3041-\u3096]/g, function(match) {
        var chr = match.charCodeAt(0) + 0x60;
        return String.fromCharCode(chr);
    });
  }

  _cleanText(text){
    return text.toLowerCase()
  }  

}

class LexicalMatching extends BaseTagSearch {
  constructor(packageData) {
    super(packageData)
    this.synonymDict = packageData.synonymDict
    this.tagInvertedIndex = packageData.tagInvertedIndex
    this.variationsDict = packageData.variationsDict
    // Object.keys(packageData.tagInvertedIndex).forEach(e=>{this.tagInvertedIndex[e]=e})
  }
  search(queryItems = []){
    let scores=[]
    //queryItems = queryItems.concat(queryItems.map(t=>this._hiraToKana(t)).filter(t=>queryItems.indexOf(t)===-1))
  
    const similarity = {}
    queryItems.forEach(q=>{
      const queryWakati = tinySegmenter.segment( q )
      let fixedQuery = q
      if (q.length>1 && q[q.length-1].match(/[a-z]/i)){
        fixedQuery = q.slice(0, q.length-2)
      }

      const filteredQueryWakati = []
      let currentText = ""
      queryWakati.forEach(text=>{
        if (this.stopWords.indexOf(text) !== -1){
          if (currentText.length > 0){
            filteredQueryWakati.push(currentText)
          }
          currentText = ""
        }else{
          currentText += text 
        }
      })
      if (currentText.length > 0){
        filteredQueryWakati.push(currentText)
      }

      const qVariations = [...(new Set([q, fixedQuery, this._hiraToKana(q), ...filteredQueryWakati]))]
      similarity[q] = []
      if (q.length > 0){

        const candidates = Object.keys(this.tagInvertedIndex).concat(Object.keys(this.variationsDict))
        const scoreDict = {} 
        qVariations.forEach(qv=>{

          const similarities = this.calcSimilarity(qv, candidates)
          for (let idx=0; idx < similarities.length; idx++){
            const simTag = similarities[idx]
            const prevScore = scoreDict[simTag.id] ? scoreDict[simTag.id].weight : 0
            scoreDict[simTag.id] = (prevScore > simTag.weight) ? scoreDict[simTag.id] : simTag 
          }
        })

        const integratedScore = Object.keys(scoreDict).map(k=>{return {id: k, weight: scoreDict[k].weight, word: scoreDict[k].word}})
        similarity[q] =  this._sortScore(integratedScore)
      }
    })
    return similarity
  }

  calcSimilarity(query, candidates) {

    
    const similarities = []

    for (let wIdx=0; wIdx<candidates.length; wIdx++){
      const word = candidates[wIdx]
      let score = 0

      if (query.length > 1 && word.indexOf(query)!==-1){
        score = 0.9 + (query.length / word.length) * 0.1
      }else{
        let screeningScore = 0    
      //const score = 1 / (1+dist)
        for (let i=0; i<query.length; i++){
          if(word.indexOf(query[i]) !== -1){
            screeningScore++
          }
        }
        screeningScore = screeningScore * 2 / (query.length + word.length)

        let dist = word.length
        if (screeningScore > 0.3){
          dist = this._levenshteinDistance(query, word)   
        }
        score = (1 - dist/word.length) * 0.9
      }

      if (score < 0.01){
        continue
      } 

      const tagSynonym = (word in this.variationsDict) ? this.variationsDict[word] : word
      const tag = (tagSynonym in this.synonymDict) ? this.synonymDict[tagSynonym] : tagSynonym

      similarities.push({id:this.tagInvertedIndex[tag], word: tagSynonym, weight:score})
    }


    // const testSimilarities = candidates.map(word=>{
    //   const dist = this._levenshteinDistance(query, word)      
    //   //const score = 1 / (1+dist)
    //  const score = 1 - dist/word.length
    //   return {id:word, weight:score}
    //   //return {id:word, weight:score}
    // }).filter(e=>e.weight>0)

    return similarities

  }

}


class SemanticMatching extends BaseTagSearch {
  constructor(packageData) {
    super(packageData)
    this.tagInvertedIndex = packageData.tagInvertedIndex
    this.variationsDict = packageData.variationsDict
    this.tagPredictionIndex = packageData.tagPredictionIndex
    // Object.keys(packageData.tagInvertedIndex).forEach(e=>{this.tagInvertedIndex[e]=e})
  }
  search(queryItems = []){
    let scores=[]
    //queryItems = queryItems.concat(queryItems.map(t=>this._hiraToKana(t)).filter(t=>queryItems.indexOf(t)===-1))
  
    const similarity = {}
    queryItems.forEach(q=>{
      
      const qVariations = [...(new Set([q, this._hiraToKana(q)]))]
      similarity[q] = []
      if (q.length > 0){

        const candidates = Object.keys(this.tagInvertedIndex).concat(Object.keys(this.variationsDict))
        const scoreDict = {} 
        qVariations.forEach(qv=>{
          if(qv in this.tagPredictionIndex){
            this.tagPredictionIndex[qv].forEach(predTag=>{
              scoreDict[predTag.id] = predTag
            })
          }
        })
    
        const integratedScore = Object.keys(scoreDict).map(k=>{return {id: k, weight: scoreDict[k].weight, word: scoreDict[k].word}})
        similarity[q] =  this._sortScore(integratedScore)
      }
    })
    return similarity
  }

}



class MatchingResultHandler{
  constructor(packageData) {
    this.expansionCandidates = packageData.expansionCandidates
    //this.tagToId = packageData.tagToId
    this.tagInfo = packageData.tagInfo
    this.scriptTags = packageData.scriptTags
    this.tagInvertedIndex = packageData.tagInvertedIndex
  }
  // format(mode, queryItems){
  //   switch(mode){
  //     // case "expandByScript":
  //     //   return this.expandByScript(queryItems)
  //     // case "expandByTagDT":
  //     //   return this.expandByTagDT(queryItems)
  //     case "expandBySelectedTags":
  //       return this.convertWithExpansion(queryItems)
  //     default:
  //       return this.convert(queryItems)
  //   }
  // }

  // expandByTagDT(queryItems){

  //   let tagId = queryItems[0]

  //   if(!(tagId in this.expansionCandidates)){
  //     return []
  //   }
  //   const expandedTags = this.expansionCandidates[tagId]
  //   // const expandedTags = this.expansionCandidates[tagId].map(e=>{
  //   //   return this.tags.filter(t=>{
  //   //     t[0]===tagId
  //   //   })[0]
  //   // })
  //   return expandedTags
  // }
  // expandByScript(queryItems){
  //   const matchedScripts = this.scriptTags.filter(s=>queryItems.every(t=>(s.tids.indexOf(t)!==-1)))
  //   const resultOfRawQuery = matchedScripts.length > 0 ? [{s: matchedScripts.map(t=>t.sid), t: queryItems}] : []
  //   const resultOfExpansion = matchedScripts.map(o=>{
  //     return {s: [o.sid], t: o.tids}
  //   })
  //   //return resultOfRawQuery.concat(resultOfExpansion)
  //    return resultOfExpansion
  // }

  convertWithExpansion(queryItems, searchFilter){

    let matchedScripts = this.scriptTags
      .filter(s=>{
        if (!searchFilter.scripts){
          return true
        }
        return searchFilter.scripts.indexOf(s.sid)!==-1
      });

    matchedScripts = matchedScripts.filter(s=>queryItems.every(t=>(s.tids.indexOf(t.id)!==-1)))

    const idx = {}
    const queryTagIds = queryItems.map(t=>t.id)
    matchedScripts.forEach(s=>{
      s.tids.forEach(t=>{
        if (queryTagIds.indexOf(t)!==-1){
           return
        }
        if (idx[t]){
          if (idx[t].indexOf(s.sid)===-1){
            idx[t].push(s.sid)
          }
        }else{
           idx[t] = [s.sid]
        }   
      })
    });
    const resultOfExpansion = Object.keys(idx).map(tag=>{
      return {s:idx[tag], t:tag, w: this.tagInfo[tag].text}
    })
    const filteredResult = this.rankAndFilter(resultOfExpansion)
    //const resultOfRawQuery = matchedScripts.length > 0 ? [{s: matchedScripts.map(t=>t.sid), t: queryItems}] : []
    //return resultOfRawQuery.concat(resultOfExpansion)
    return filteredResult
  }

  rankAndFilter(tagItems){
    // const tagItems = input.forEach(e=>{
    //   const a = this.tags.filter(t=>t[0]===e.t)[0]
    //   e.val = a[]
    //   return a
    // })
    tagItems.forEach(e=>{
      e.weight = this.tagInfo[e.t].weight * e.s.length
    })

    const sortedItems = tagItems.sort((a,b)=>{
      return b.weight - a.weight;
    })
 
    return sortedItems
  }

  convert(queryItems, searchFilter){ 

    let matchedScripts = this.scriptTags
      .filter(s=>{
        if (!searchFilter.scripts){
          return true
        }
        return searchFilter.scripts.indexOf(s.sid)!==-1
      });

    matchedScripts = matchedScripts.filter(s=>queryItems.every(t=>(s.tids.indexOf(t.id)!==-1)))
    //const matchedScripts = this.scriptTags.filter(s=>(s.tids.indexOf(query)!==-1))
    const lastQuery = queryItems.slice(-1)[0]
    const resultOfRawQuery = matchedScripts.length > 0 ? [{s: [...new Set(matchedScripts.map(t=>t.sid))], t: lastQuery.id, w: lastQuery.word}] : []
    return resultOfRawQuery
  }

  retrieveCandidateTags(queries, withExpansion, searchFilter){

    let result = queries.map(q=>{
       return withExpansion ? this.convertWithExpansion(q, searchFilter) : this.convert(q, searchFilter)
      
    })
    result = result.filter(r=>r.length>0)
    .reduce((p,r)=>{
      return p.concat(r)
    },[])

    const tempKeys = []
    const uniquifiedResult = []
    //TO BE FIXED
    result.forEach(candidateTag=>{
  
      const key = candidateTag.t
      if (tempKeys.indexOf(key)===-1){
        tempKeys.push(key)
        uniquifiedResult.push(candidateTag)
      }

    })
    return uniquifiedResult
  }

  _formQuery(prerequisite, optional){
    // const andList = prerequisite.map(t=>t.id)
    // const orList = optional.map(t=>t.id)
    const andList = prerequisite
    const orList = optional
    return this._formLogicalOperator(andList, orList)
    // debugger
    // return andList.concat(orList)
  }

  _formLogicalOperator(andList, orList){
    if (andList.length ===0 && orList.length ===0) {
      return []
    }

    return orList.length===0 ? [andList] : orList.map(item=>{
      return [...andList, item]
    })
    // return (andList.length > 0 ? [andList]:[]).concat(orList.map(item=>{
    //   return [...andList, item]
    // }))
  }

  process({query, matchingResult, searchFilter, withExpansion}){

    const queries = this._formQuery(matchingResult.prerequisite, matchingResult.optional)
    const candidateTags = this.retrieveCandidateTags(queries, withExpansion, searchFilter)
    //let result = this._expandMatchingResult(matchingResult, withExpansion)
    //result = this._removeQueryFromResult(this._cleanTag(selectedTags), result)
    // const mode = withExpansion ? "expandBySelectedTags" : "noExpansion"
    return candidateTags
  }
  _isTextEmpty(text){
    return !!(text||"").match(new RegExp('^(|[ 　]+)$'))
  }

}

export class TagMatchingManager {
  constructor(packageData) {
    this.packageData = {
      scriptInvertedIndex: packageData.inverted_index,
      synonymDict: packageData.synonym_dict,
      variationsDict: packageData.variations_dict,
      // invPostProbDist: data.script_by_id,
      categoryData: packageData.talk_script.body,
      tags: packageData.tags,
      tagInvertedIndex: packageData.tag_inverted_index,
      //tagToId: packageData.tag_to_id,
      matchingScript: packageData.script,
      expansionCandidates: packageData.tag_expansion_candidates
    }

    const scripts = this.packageData.categoryData.filter(s=>((s.type=="leaf") && ("questions" in s)))
    this.packageData.scriptTags = []
    scripts.forEach(s=>{
      s.questions.forEach(q=>{
        const tids = q.split(",")
          .map(word=>this._cleanText(word))
          .filter(t=>(t in this.packageData.tagInvertedIndex))
          .map(t=>this.packageData.tagInvertedIndex[t])
        const sid = s.id
        this.packageData.scriptTags.push({sid, tids})
      })
    })

    const tagPredictionIndex = {}
    this.packageData.tagInfo = {}
    this.packageData.tags.forEach(t=>{
      const tagItem = Object.assign(
        ...[
          'id',
          'index',
          'text',
          'yomi',
          'relations',
          'attributes',
          'weight',
          'numOfScripts',
        ].map((k, i) => ({ [k]: t[i] }))
      );

      this.packageData.tagInfo[tagItem.id] = tagItem
      if (tagItem.relations){
        Object.keys(tagItem.relations).forEach(text=>{
          const relation = {"id": tagItem.id, "word": tagItem.text, "weight": tagItem.relations[text]}
          tagPredictionIndex[text] = tagPredictionIndex[text] ? tagPredictionIndex[text].push(relation) : [relation]
        })
      }
    })  
  
    this.aggregatorThresthold = {lexScore: 0.9, tagWeight: 0}
    this.packageData.tagPredictionIndex = tagPredictionIndex
    this.maxNumOfCandidates = 10
    this.lexMatching = new LexicalMatching(this.packageData)
    this.semanticMatching = new SemanticMatching(this.packageData)
    this.matchingResultHandler = new MatchingResultHandler(this.packageData)
    //this.lexts = new LexTagSearch(packageData)
  }

  extractTags(sentence) {

    const cleanedSentence = this._cleanText(sentence)
    const tags = []
    for (const word in this.packageData.tagInvertedIndex){
      if (cleanedSentence.indexOf(word) != -1){
        tags.push(word)
      } 
    }

    const candidateTags = tags.map(t=>this.packageData.tagInvertedIndex[t])
    const uniqueTags = [...(new Set(candidateTags))].sort((a,b)=>{
      return b.length - a.length
    })

    return uniqueTags
  }

  getExactlyMatchedTags(queryItems, synonym=true) {
    
    if (synonym){
      return queryItems.map(q=>{
        const cleaned_q = this._cleanText(q)
        return this.packageData.tagInvertedIndex[cleaned_q] ?  this.packageData.tagInvertedIndex[cleaned_q] : null
      })

    }else{
      return queryItems.map(q=>{
        const cleanedQuery = this._cleanText(q)
        for (let i=0; i<this.packageData.tags.length; i++){
          if (this.packageData.tags[i][1] === cleanedQuery){
            return this.packageData.tags[i][0]
          }
        } 
      })
    }
  }

  getSynonyms(word){
    const tagSynonym = (word in this.packageData.variationsDict) ? this.packageData.variationsDict[word] : word
    const tag = (tagSynonym in this.packageData.synonymDict) ? this.packageData.synonymDict[tagSynonym] : tagSynonym
    const synonymDictEntries = Object.entries(this.packageData.synonymDict)
    const synonyms = synonymDictEntries.filter(e=>e[1]===tag).map(e=>e[0])
    return synonyms
  }

  getSearchResult(selectedTags=[], query="", searchFilter={}){

    const prerequisite = this._getPrerequisites(selectedTags)

    let withSearch = false
    let withExpansion = false
    let matchingScores = []
    if (!this._isTextEmpty(query)){
      matchingScores = this._calculateScores(query)
      withSearch = true
    }else if (prerequisite.length>0){
      withExpansion = true
    }else{
      matchingScores = this._getDefaultScores()
    }

    const optional = this._prescreenMatchingResult({matchingScores, searchFilter, withSearch})
    const matchingResult = {prerequisite, optional}

    const result = this.matchingResultHandler.process({query, matchingResult, searchFilter, withExpansion})
    return result

  }

  _getDefaultScores(){
    const tags = this.packageData.tagInfo
    return　{ default: Object.keys(tags).map(t=>{return {id:tags[t].id, word: tags[t].text, weight:0.0}}) }
  }

  _getPrerequisites(selectedTags){
    return this._cleanTag(selectedTags).map(t=>{
      //needed?
      return {"id": this.packageData.tagInvertedIndex[t], "word":t}
    })

  }

  _calculateScores(query){

    const queryItems = this.formQueryItems(query)
    if (queryItems.length===0){
      return []
    }
    
    const lexMatchScores = this.lexMatching.search(queryItems)
    const semMatchScores = this.semanticMatching.search(queryItems)
    const scores = {lexMatchScores, semMatchScores}
    const aggregatedScore = this._scoreAggregator(scores)
    return aggregatedScore
  }

  _scoreAggregator(scores){

    const aggregatedScore = {}

    let keySet = Object.keys(scores).map(type=>{
      return Object.keys(scores[type])
    }).reduce((p,c) =>{
      return p.concat(c)
    })
    
    keySet = [...new Set(keySet)]

    keySet.forEach(k=>{

      scores.lexMatchScores[k] = scores.lexMatchScores[k]||[]
      scores.semMatchScores[k] = scores.semMatchScores[k]||[]

      let aggregatedScoreByQuery = []
      
      let lexMatchScores = scores.lexMatchScores[k]
      lexMatchScores = lexMatchScores.filter(t=>{
        return this.packageData.tagInfo[t.id].weight > this.aggregatorThresthold.tagWeight
      })
      const semMatchScores = scores.semMatchScores[k]

      const lexHighScores = lexMatchScores.filter(t=>{
        return t.weight > this.aggregatorThresthold.lexScore
      })

      if (semMatchScores.length > 0){
        if (lexHighScores.length > 0){
          aggregatedScoreByQuery = lexHighScores
          aggregatedScoreByQuery = aggregatedScoreByQuery.concat(semMatchScores)
        }else{
          aggregatedScoreByQuery = semMatchScores
          aggregatedScoreByQuery = aggregatedScoreByQuery.concat(lexMatchScores.slice(0,1))
        }
      }else{
        if (lexHighScores.length > 0){
          aggregatedScoreByQuery = lexHighScores
        }else{
          aggregatedScoreByQuery = lexMatchScores
        }
      }
      
      aggregatedScore[k] = aggregatedScoreByQuery
    })
    return  aggregatedScore
  }


  _cleanTag(selectedTags){
    return (selectedTags||[]).map(t=>this._cleanText(t))
  }

  formQueryItems( query="" ){
    const splitter = new RegExp("[ |　]+")
    return ((query||"").split(splitter)).filter(t=>t.length>0)
    //return [...cleanedTags, ...((query||"").split(splitter))].filter(t=>t.length>0)
  }

  // _removeQueryFromResult(queryItems, result){
  //   const queryTags = this.getExactlyMatchedTags(queryItems, true)
  //   const filteredResult = result.map(expandedTags=>{      
  //     queryTags.forEach(qt=>{
  //       let index = expandedTags.t.indexOf(qt)
  //       while (index !== -1){
  //         expandedTags.t.splice(index, 1)     
  //         index = expandedTags.t.indexOf(qt)   
  //       }
  //     })

  //     return candidateTags.filter(expandedTags=>{return expandedTags.t.length>0})
  //   })

  //   return filteredResult
  // }

  _expandMatchingResult(matchingResult, withExpansion=false){

    const mode = withExpansion ? "expandBySelectedTags" : "noExpansion"
    const expansionQueries = this._formQuery(matchingResult.prerequisite, matchingResult.optional)
    const result = this._expandByQuery(expansionQueries, mode)
    return result
  }

  _prescreenMatchingResult({matchingScores, searchFilter, withSearch}){

    let screendResult = []
    for(let key in matchingScores){
      if (matchingScores[key].length === 0) continue

      let scores = []
      if (searchFilter.scripts){
        scores = matchingScores[key].map(score=>{
   
          const tagScriptSet = new Set(this._getScriptIdsFromTagId(score.id))
          const weight = searchFilter.scripts.filter(s=>tagScriptSet.has(s)).length / searchFilter.scripts.length

          //if (weight>0) debugger
          if (withSearch){
            return score
          }else{
            return Object.assign(score, {weight})
          }
        
        }).filter(score=>score.weight>0)
        
      }else{
        scores = matchingScores[key]
      }

      //const firstCandidate = simScores[key][0]
      // if (firstCandidate.weight >= this.threshold){
      //   const numOfCandidates = 1
      //   filteredScores.prerequisite.push(firstCandidate)//scores[key].slice(numOfCandidates-1).map(e=>e.id)
      // }else{
     
      screendResult.push(...scores)
      // }
    }

    screendResult = screendResult.sort((a,b)=>{
      return b.weight - a.weight
    }).slice(0, this.maxNumOfCandidates)
    return screendResult
  }


  _getScriptIdsFromTagId(tagId){
    const tagIndex = this.packageData.tagInfo[tagId].index
    return this.packageData.scriptInvertedIndex[tagIndex].scripts.map(s=>{
      const index = s[0]
      return this.packageData.matchingScript[index].id
    })
  }

  _cleanText(text){
    return text.toLowerCase()
  }
  _isTextEmpty(text){
    return !!(text||"").match(new RegExp('^(|[ 　]+)$'))
  }
}
