import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { eventBus } from '@/eventBus';

@Component({
  props: {
    currentValue: Object,
    lastScript: Object,
    withEnquete: {
      type: Boolean,
      default: false,
    },
    withSuggestion: {
      type: Boolean,
      default: false,
    },
  },
})
export default class ResourceListFooterMixin extends Vue {
  showLastMessage = false;
  feedbackResult = '';

  resolveMessage = this.$store.state.constObj.ASK_FEEDBACK_CHOICES.RESOLVED;
  unsolvedMessage = this.$store.state.constObj.ASK_FEEDBACK_CHOICES.UNRESOLVED;
  afterResulvedMessage = this.$store.state.constObj.AFTER_RESOLVED_MESSAGE;
  afterUnResulvedMessage = this.$store.state.constObj.AFTER_UNRESOLVED_MESSAGE;
  goToTopLabel = this.$store.state.constObj.GO_TO_TOP_LABEL;

  get afterFeedbackMessage() {
    if (this.feedbackResult === 'unresolved') {
      return this.afterUnResulvedMessage;
    }
    return this.afterResulvedMessage;
  }

  @Watch('currentValue')
  @Watch('$store.state.navigation.index')
  @Watch('$store.state.navigation.routes')
  onChangeCurrentValue() {
    this.showLastMessage = false;
    this.feedbackResult = '';
  }
  async resolved() {
    this.feedbackResult = 'resolved';

    if (this.withEnquete) {
      this.$store.dispatch('enquete/updateContents', {
        type: this.$store.state.constObj.ENQUETE.TYPE.RESOLVED,
        action: this.$store.state.constObj.ENQUETE.ACTION.FEEDBACK_RESOLVED,
        item: this.currentValue,
      });
      this.$store.dispatch('enquete/updateShow', true);
    }

    this.setShowLastMessage(true);
    setTimeout(() => {
      this.$emit('scrollBottom');
    }, 300);

    this.$store.commit('ticket/setResetFlag', true);
    const newTicket = this.updateTicket({
      item: this.currentValue,
      feedback: 'resolved',
    });
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  async unresolved() {
    this.feedbackResult = 'unresolved';

    if (this.withSuggestion) {
      this.$store.dispatch('enquete/updateContents', {
        type: this.$store.state.constObj.ENQUETE.TYPE.UNRESOLVED,
        action: this.$store.state.constObj.ENQUETE.ACTION.FEEDBACK_UNRESOLVED,
        item: this.currentValue,
      });
      this.$store.dispatch('enquete/updateShow', true);
    }

    this.setShowLastMessage(true);
    setTimeout(() => {
      this.$emit('scrollBottom');
    }, 300);

    this.$store.commit('ticket/setResetFlag', true);
    const newTicket = this.updateTicket({
      item: this.currentValue,
      feedback: 'unresolved',
    });
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  updateTicket({ item, feedback }) {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    }
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }

    return this.generateTicket(item, startTime, query, tagActiveSet, feedback);
  }
  generateTicket(item, startTime, query, tagActiveSet, feedback) {
    const commonTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      product_type: this.$store.state.productType,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
    };

    let newTicket = {
      ...commonTicket,
      status: 'answered',
      items: item.items,
      status_feedback: 'done',
      feedback: feedback,
    };

    return newTicket;
  }
  setShowLastMessage(boolean) {
    this.showLastMessage = boolean;
  }
  async reset(e) {
    eventBus.$emit('resetWithScrollTop');
    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = this.generateClearTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  generateClearTicket() {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    }
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }
    const commonTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      product_type: this.$store.state.productType,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
    };

    const newTicket = {
      ...commonTicket,
      status: 'open',
    };

    return newTicket;
  }
}
