import Vue from 'vue';
import Component from 'vue-class-component';
import { eventBus } from '@/eventBus';
import dataResource from '@/common/dataResource';
import talkScript from '@/common/talkScript';

@Component({
  props: {
    searchText: String,
    selectedTags: Array,
    inputEmpty: Boolean,
    filteredItems: Array,
  },
})
export default class TaggedInputMixin extends Vue {
  canEnter = false;
  keydownCode = '';
  isComposing = false;
  barWidth = 130;
  spaceKeyCode = 32;

  get show() {
    return this.$store.state.tagSearch.show;
  }
  get noReccomend() {
    return (
      this.$store.state.tagSearch.filteredItems.length === 0 &&
      this.$store.state.tagSearch.selectedTags.values().length === 0 &&
      this.$store.state.tagSearch.candidateTags.length === 0
    );
  }
  get placeholderMessage() {
    return this.$store.state.tagSearch.selectedTags.values().length === 0
      ? this.$store.state.constObj.INITIAL_INPUT_MESSAGE
      : '';
  }
  async customUpdateSearchText(val) {
    if (val.toLowerCase().indexOf('faq') === -1) {
      this.updateSearchText(val);
    } else {
      await dataResource.ready({ resourceName: 'talkScript' });
      await talkScript.ready();
      let talkScripts = await dataResource.getList({
        resourceName: 'talkScript',
      });
      talkScripts = talkScripts
        .filter(s => {
          const match = s.text.match(/\[([^\]]*)\]/);
          if (match) {
            const tsId = match[1];
            if (tsId.toLowerCase().indexOf(val.toLowerCase()) !== -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, 10);
      if (talkScripts.length === 0) {
        this.updateSearchText(val);
        return;
      }
      this.$store.commit('tagSearch/setSearchText', val);
      this.$store.commit('tagSearch/setFilteredItems', talkScripts);
    }
  }
  updateSearchText(searchText) {
    // TO BE FIXED
    this.$store.dispatch('tagSearch/setSearchText', searchText, 'both');
  }
  async removeTag(tag) {
    if (this.$store.state.user.isIphone && this.$store.state.onIME) {
      eventBus.$emit('toggleIme', false);
      return;
    }
    await this.$store.dispatch('tagSearch/removeSelectedTag', tag);
    this.setShow(true);

    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = this.updateOpenTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  async onFocus() {
    if (this.show) {
      return;
    }
    this.setShow(true);

    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = this.updateOpenTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  async onFocusWithScroll() {
    if (this.show) {
      return;
    }
    this.setShow(true);
    if (!this.$store.state.user.isIphone) {
      setTimeout(() => {
        eventBus.$emit('scrollToSearch');
      }, 300);
    }

    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = this.updateOpenTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  setShow(show) {
    this.$store.commit('tagSearch/setShow', show);
  }
  async keydownHandler(e) {
    const exKeyCode = this.keydownCode;
    this.keydownCode = e.keyCode;
    this.isComposing = e.isComposing;

    if (this.notContinuousSpace(exKeyCode, e.keyCode) && this.searchText) {
      const newTicket = this.updateEnterTicket();
      await this.$ticket.setData(newTicket);
      await this.$ticket.post();
      return;
    }

    if (this.tagRemoveCondition(e)) {
      await this.$store.dispatch('tagSearch/removeLastTag');

      if (this.$store.state.ticket.resetFlag) {
        eventBus.$emit('resetTicket');
        return;
      }
      const newTicket = this.updateOpenTicket();
      await this.$ticket.setData(newTicket);
      this.$ticket.post();
    }
  }

  notContinuousSpace(exCode, newCode) {
    return exCode !== this.spaceKeyCode && newCode === this.spaceKeyCode;
  }

  tagRemoveCondition(e) {
    return (
      this.onlyBackspace(e) && !this.searchText && this.selectedTags.length > 0
    );
  }
  onlyBackspace(e) {
    return e.keyCode === 8 && !e.shiftKey && !e.ctrlKey && !e.altKey;
  }
  async keyupHandler(e) {
    if (
      this.$store.state.user.isSP ||
      this.$store.state.user.isEdge ||
      !this.isDetermined(e)
    ) {
      return;
    }

    const openStatus = !this.noReccomend;
    if (openStatus && this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    if (!openStatus) {
      this.$store.commit('ticket/setResetFlag', true);
    }
    const newTicket = this.updateEnterTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  isDetermined(e) {
    return (
      (this.onlyEnterKey(e) && this.keydownCode !== e.keyCode) ||
      (e.code &&
        e.code === 'Enter' &&
        e.keyCode === 229 &&
        e.isComposing !== this.isComposing)
    );
  }
  onlyEnterKey(e) {
    return e.keyCode === 13 && !e.shiftKey && !e.ctrlKey && !e.altKey;
  }
  onKeypress(e) {}
  onCompositionStart() {
    eventBus.$emit('toggleIme', true);
  }
  async onCompositionEnd() {
    eventBus.$emit('toggleIme', false);
    if (!this.$store.state.user.isSP && !this.$store.state.user.isEdge) {
      return;
    }

    const openStatus = !this.noReccomend;
    if (openStatus && this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    if (!openStatus) {
      this.$store.commit('ticket/setResetFlag', true);
    }
    const newTicket = this.updateEnterTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  updateOpenTicket() {
    const { startTime, tagActiveSet } = this.updateCommonTicket();
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }
    const newTicket = this.generateTicket(startTime, query, tagActiveSet);
    newTicket.status = 'open';
    return newTicket;
  }
  updateEnterTicket() {
    const { startTime, tagActiveSet } = this.updateCommonTicket();
    const query = this.$store.state.tagSearch.searchText;
    if (query) {
      this.$store.commit('ticket/addHistoryQuery', query);
    }
    const newTicket = this.generateTicket(startTime, query, tagActiveSet);
    newTicket.status = this.noReccomend ? 'searchNoScript' : 'open';
    return newTicket;
  }
  updateCommonTicket() {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    const categoryTags = this.$store.getters['tagSearch/selectedCategoryTags'];
    const actionValue = categoryTags.length === 0 ? 'search' : 'category';
    this.$store.commit('ticket/addHistoryActionFaqChannel', {
      type: '',
      value: actionValue,
    });

    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    }
    return { startTime, tagActiveSet };
  }
  generateTicket(startTime, query, tagActiveSet) {
    const newTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      product_type: this.$store.state.productType,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
    };

    return newTicket;
  }
  calcBarWidth(count) {
    const length = count >= 100 ? this.barWidth : this.barWidth * (count / 100);
    return length > 2 ? length : 2;
  }
  closeList() {
    if (this.$store.state.user.isIphone && this.$store.state.onIME) {
      eventBus.$emit('toggleIme', false);
      return;
    }
    this.setShow(false);
  }
  created() {
    eventBus.$on('selectNextTag', params => {
      this.$refs.searchInput.focus();
    });
  }
}
