import axios from 'axios';

class Resource {
  constructor() {
    this.ready();
  }
  ready() {
    if (!this.readyPromise) {
      this.readyPromise = this.prepare();
    }
    return this.readyPromise;
  }
  async prepare() {
    const startTime = new Date().getTime();
    const resourceUrl = `${process.env.RESOURCE_FILE_URL}?v=${this.randomNums()}`;
    const response = await axios.get(resourceUrl);
    const data = response.data;
    //console.log('script_package fetch time', new Date().getTime() - startTime);
    return data;
  }
  randomNums() {
    return Math.floor(Date.now() / 1000 / 60);
  }
}

const resource = new Resource();

export default resource;
