import resource from '@/common/resource';

export default {
  namespaced: true,
  actions: {
    async init({ commit }) {
      await resource.ready();
    },
  },
};
