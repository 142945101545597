import talkScript from '../talkScript';

class TalkScriptResource {
  cache = new WeakMap();
  ready() {
    return talkScript.ready();
  }
  async getList(params) {
    const list = params.talkScriptId
      ? await talkScript.listByParentId(params.talkScriptId)
      : await talkScript.list();
    return list && list.map(this.convertItem);
  }
  getListSync(params) {
    const list = params.talkScriptId
      ? talkScript.listByParentIdSync(params.talkScriptId)
      : talkScript.listSync();
    return list && list.map(this.convertItem);
  }
  isEquals(a, b) {
    return a.talkScriptId === b.talkScriptId;
  }
  topItem = {
    text: 'トップ',
    viewType: 'talkScript',
    parentViewType: 'talkScript',
    componentName: 'ResourceList',
    resourceName: 'talkScript',
    talkScriptId: '#',
  };
  getItemSync(params) {
    if (!params.talkScriptId || params.talkScriptId === '#') {
      return this.topItem;
    } else {
      const item = talkScript.getSync(params.talkScriptId);
      return item && this.convertItem(item);
    }
  }

  async getItem(params) {
    if (!params.talkScriptId || params.talkScriptId === '#') {
      return this.topItem;
    } else {
      const item = await talkScript.get(params.talkScriptId);
      return item && this.convertItem(item);
    }
  }
  convertItem = item => {
    if (!this.cache.has(item)) {
      const convertedItem = {
        text: item.text,
        title: item.title,
        kana: item.text,
        caption: item.value,
        talkScriptId: item.id,
        componentName: 'ResourceList',
        parentViewType: 'talkScript',
        ancesters: item.ancesters,
        keywords: item.keywords,
        originalKeywords: item.originalKeywords,
        keywordsText: item.keywordsText,
        highlightedText: item.highlightedText,
        parent: this.getItemSync({
          talkScriptId: item.parent,
        }),
        items: item.items,
      };
      if (item.type === 'node') {
        this.cache.set(
          item,
          Object.assign(convertedItem, {
            resourceName: 'talkScript',
            viewType: 'talkScript',
            talkScriptType: 'node',
          })
        );
      } else if (item.type === 'leaf' && item.scenario) {
        this.cache.set(
          item,
          Object.assign(convertedItem, {
            resourceName: 'scenario',
            scenarioId: item.scenario,
            viewType: 'scenario',
            talkScriptType: 'leaf',
          })
        );
      } else {
        this.cache.set(
          item,
          Object.assign(convertedItem, {
            resourceName: 'talkScript',
            viewType: 'result',
            talkScriptType: 'leaf',
          })
        );
      }
    }
    return this.cache.get(item);
  };
}

export const talkScriptResource = new TalkScriptResource();
