<template>
  <div class="VerticalNavigation">
    <div class="navigationContentWrapper">
      <div ref="navigationContent" class="navigationContent">
        <div v-for="(route, routeIndex) in routes" :key="routeIndex">
          <component
            :is="
              route.componentName === 'ResourceList'
                ? 'Scenario'
                : route.componentName
            "
            :index="routeIndex"
            :currentValue="route"
            :prevValue="
              $store.state.navigation.routes[baseIndex + routeIndex - 1]
            "
            :nextValue="
              $store.state.navigation.routes[baseIndex + routeIndex + 1]
            "
            :currentLocal="locals[routeIndex]"
            :prevLocal="locals[routeIndex - 1]"
            :nextLocal="locals[routeIndex + 1]"
            :localActive="routeIndex === localIndex"
            :active="baseIndex + routeIndex === $store.state.navigation.index"
          ></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import NavigationMixin from '@/mixins/NavigationMixin';

@Component({})
export default class VerticalNavigation extends mixins(NavigationMixin) {}
</script>

<style lang="scss" scoped>
.VerticalNavigation {
  box-sizing: border-box;
  height: 100%;
}

.navigationContentWrapper {
  width: 100%;
  overflow: hidden;
}

.navigationContent {
  width: 100%;
}

.ancester {
  font-size: 14px;
}
</style>
