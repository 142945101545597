import Vue from 'vue';
import Vuex from 'vuex';
import tagSearch from './modules/tagSearch';
import user from './modules/user';
import history from './modules/history';
import navigation from './modules/navigation';
import resource from './modules/resource';
import ticket from './modules/ticket';
import defaultConst from '@/const/index';
import productConst from 'product/const/index';

Vue.use(Vuex);

const defaultStore = {
  state: () => ({
    onIME: false,
    ready: false,
    productType: '',
    constObj: undefined,
  }),
  getters: {
    isReady: state => {
      return state.ready;
    },
  },
  mutations: {
    setIME(state, payload) {
      state.onIME = payload;
    },
    setReady(state, payload) {
      state.ready = payload;
    },
    setProductType(state, payload) {
      state.productType = payload;
    },
    setConstObj(state, payload) {
      state.constObj = payload;
    },
  },
  actions: {
    async Initialize({ dispatch, commit }, constObject) {
      const constObj = constObject || { ...defaultConst, ...productConst };
      commit('setConstObj', constObj);
      const productType = process.env.PRODUCT_TYPE || '';
      commit('setProductType', productType);

      // Initialize modules
      await dispatch('resource/init');
      await dispatch('tagSearch/init');
      await dispatch('user/init');
      await dispatch('history/init');

      // Make Ready true
      commit('setReady', true);
    },
  },
  modules: {
    tagSearch: tagSearch,
    user: user,
    history: history,
    navigation: navigation,
    resource: resource,
    ticket: ticket,
  },
  plugins: [],
};

export const generateStore = ({ modules = {}, plugins = [] }) =>
  new Vuex.Store({
    ...defaultStore,
    modules: { ...defaultStore.modules, ...modules },
    plugins: [...defaultStore.plugins, ...plugins],
  });

export default new Vuex.Store(defaultStore);
