export const extractQueryParams = queryString => {
  const concatenatedQueries = queryString.substring(1);
  return concatenatedQueries.length === 0
    ? {}
    : concatenatedQueries
        .split('&')
        .map(p => p.split('='))
        .reduce((obj, e) => ({ ...obj, [e[0]]: decodeURI(e[1]) }), {});
};

export const constructQueryString = params =>
  '?' +
  Object.keys(params)
    .map(key => {
      let value = params[key];
      if (value instanceof Array) {
        value = value.join(',');
      }
      if (!value) {
        return;
      }
      return `${key}=${value}`;
    })
    .filter(a => a)
    .join('&');
