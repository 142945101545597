import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    text: String,
    targets: Array,
  },
})
export default class HighlightMixin extends Vue {
  get htmlString() {
    if (!this.targets || !this.targets.length) {
      return this.text;
    }

    const patternStr = '(?:' + this.targets.join('|') + ')';
    const caseInsensitiveMatching = new RegExp(patternStr, 'gi');

    return this.text.replace(caseInsensitiveMatching, matched =>
      this.stringDecorator(matched)
    );
  }

  stringDecorator(str) {
    return `<span class="hl">${str}</span>`;
  }
}
