// import dataResource from '@/common/dataResource';

export default {
  namespaced: true,
  state: {
    index: 0,
    routes: [
      {
        componentName: 'ResourceList',
        resourceName: 'talkScript',
        talkScriptId: '#',
        viewType: 'talkScript',
      },
    ],
  },
  getters: {
    getIndex: state => {
      return state.index;
    },
    getRoutes: state => {
      return state.routes;
    },
    canMovePositionTo: state => index => {
      return state.routes[index] != null;
    },
    canMovePositionBy: (state, getters) => relative => {
      return getters.canMovePositionTo(state.index + relative);
    },
    getEfficientRoutes: state => {
      return state.routes.slice(0, state.index + 1);
    },
    canClose: () => index => {
      return index > 0;
    },
    getRoute: state => {
      return state.routes[state.index];
    },
  },
  mutations: {
    setIndex(state, payload) {
      state.index = payload;
    },
    setRoutes(state, payload) {
      state.routes = payload;
      state.index = payload.length - 1;
    },
    open(state, { index, route }) {
      state.routes = state.routes.slice(0, index + 1).concat([route]);
      state.index = index + 1;
    },
    close(state, payload) {
      if (payload > 0) {
        state.routes = state.routes.slice(0, payload);
        state.index = payload - 1;
      }
    },
  },
  actions: {
    async movePositionTo({ commit, getters }, index) {
      if (getters.canMovePositionTo(index)) {
        commit('setIndex', index);
      }
    },
    async movePositionBy({ getters, dispatch }, relative) {
      await dispatch('movePositionTo', getters.getIndex + relative);
    },
  },
};
