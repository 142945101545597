import resource from './resource';
import { utilityModules } from './util';

class TalkScript {
  readyPromise = null;
  constructor(_resource) {
    this.resource = _resource;
  }
  async list() {
    await this.ready();
    return this.talkScripts;
  }
  async listByParentId(parentId) {
    await this.ready();
    return this.mapByParentId[parentId || '#'];
  }
  listSync() {
    return this.talkScripts;
  }
  listByParentIdSync(parentId) {
    return this.mapByParentId[parentId || '#'];
  }

  async get(id) {
    await this.ready();
    return this.mapById[id];
  }
  getSync(id) {
    return this.mapById && this.mapById[id];
  }
  /* eslint-disable no-sync */
  getSyncWithAncesters(id) {
    const target = this.getSync(id);
    if (target && !target.ancesters) {
      target.ancesters = [];
      for (
        let current = this.getSync(target.parent);
        current;
        current = this.getSync(current.parent)
      ) {
        target.ancesters.unshift(current);
      }
    }
    return target;
  }

  ready() {
    if (!this.readyPromise) {
      this.readyPromise = this.prepare();
    }
    return this.readyPromise;
  }
  async prepare() {
    const data = await this.resource.ready();
    const talkScripts = data.talk_script.body;
    const synonymDict = data.synonym_dict;
    const mapByParentId = {};
    const mapById = {};
    for (const item of talkScripts) {
      if (!mapByParentId[item.parent]) {
        mapByParentId[item.parent] = [];
      }
      mapByParentId[item.parent].push(item);
      mapById[item.id] = item;
    }
    this.talkScripts = talkScripts;
    this.mapByParentId = mapByParentId;
    this.mapById = mapById;

    for (const item of talkScripts) {
      const keywords = new Set();
      const originalKeywords = new Set();
      for (const question of item.questions || []) {
        for (const keyword of question.split(',')) {
          const cleanedKeyword = utilityModules.cleanText(keyword);
          const normalizedKeyword = (cleanedKeyword in synonymDict) ? synonymDict[cleanedKeyword] : cleanedKeyword
          keywords.add(normalizedKeyword);
          originalKeywords.add(keyword)
        }
      }

      item.keywords = keywords
      item.originalKeywords = originalKeywords;
      item.keywordsText = [...keywords].join(", ")
      item.highlightedText = item.text
      keywords.forEach(kw=>{
        item.highlightedText = item.highlightedText.replace(kw, `<mark>${kw}</mark>`)
      })
    }
    const categoryMap = {};
    for (const item of talkScripts) {
      this.getSyncWithAncesters(item.id);
      if (item.type === 'node' && item.ancesters.length === 0) {
        categoryMap[item.text] = categoryMap[item.text] || [];
        categoryMap[item.text].push(item);
      }
    }
    this.categoryMap = categoryMap;
    this.categories = Object.values(categoryMap).map((items, index) => ({
      id: 'category' + index,
      text: items[0].text,
      items,
    }));
  }
  // async filterList({ selectedTagSet, byStep, searchText }) {
  //   console.log('searchText', searchText);
  //   await this.ready();
  //   let lastNodeTag = null;
  //   const selectedKeywordTags = [];
  //   for (const tag of selectedTagSet) {
  //     if (tag.type === 'node') {
  //       lastNodeTag = tag;
  //     } else if (tag.type === 'keyword') {
  //       selectedKeywordTags.push(tag);
  //     }
  //   }
  //   const list = searchText
  //     ? (await search.search(searchText)).map(id => this.getSync(id))
  //     : this.talkScripts;

  //   const filteredList = list.filter(item => {
  //     return (
  //       item.type === 'leaf' &&
  //       (!lastNodeTag ||
  //         item.ancesters.some(_ancester => _ancester.id === lastNodeTag.id)) &&
  //       selectedKeywordTags.every(tag => item.keywords.has(tag.title))
  //     );
  //   });
  //   return filteredList;
  // }

  // async getCandidateTags({ selectedTagSet, list }) {
  //   await this.ready();
  //   if (!list) {
  //     return [];
  //   }
  //   const keywordMap = {};
  //   const anscesterSet = new Set();
  //   for (const item of list) {
  //     // if (item.type === 'leaf') {
  //     for (const keyword of item.keywords) {
  //       keywordMap[keyword] = keywordMap[keyword] || [];
  //       keywordMap[keyword].push(item);
  //     }
  //     for (const anscester of item.ancesters) {
  //       anscesterSet.add(anscester);
  //     }
  //     // }
  //   }

  //   const categoryTags = firstCategory(
  //     anscesterSet,
  //     item => item.ancesters.length
  //   );
  //   const keywordTags = Object.entries(keywordMap)
  //     .filter(
  //       ([keyword, items]) => items.length < list.length && items.length > 1
  //     )
  //     .sort((a, b) => b[1].length - a[1].length)
  //     .map(([keyword, items]) => ({
  //       id: 'keyword:' + keyword,
  //       type: 'keyword',
  //       title: keyword,
  //       items,
  //     }))
  //     .slice(0, 20);
  //   return categoryTags.concat(keywordTags);
  // }
}

const talkScript = new TalkScript(resource);
if(typeof window === "object"){
  window.talkScript = talkScript;
}
export default talkScript;
