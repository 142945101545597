import { fromRoute } from '@/common/resourceNavigationUtil';

const maxNumOfHistories = 10;
const localStorageKeyName = `sAIFaqClient-history-${process.env.PRODUCT_ID}`;
function cleanObject(obj) {
  const copied = {};
  for (const key of Object.keys(obj)) {
    if (obj[key]) copied[key] = obj[key];
  }
  return copied;
}
function setItemToLocalStorage(key, item) {
  localStorage.setItem(key, JSON.stringify(item));
}

export default {
  namespaced: true,
  state: {
    list: [],
  },
  getters: {
    getHistory: state => {
      return state.list;
    },
    getScriptHistory: state => {
      return state.list.filter(item => {
        return item.talkScriptId && item.viewType !== 'talkScript';
      });
    },
  },
  mutations: {
    setHistory(state, list) {
      state.list = list;
    },
  },
  actions: {
    init({ commit }) {
      commit(
        'setHistory',
        JSON.parse(localStorage.getItem(localStorageKeyName) || null) || []
      );
    },
    async add({ getters, commit }, route) {
      const resourceParam = await fromRoute(route);
      if (resourceParam.talkScript.viewType === 'talkScript') {
        return;
      }
      let newList = getters.getHistory.filter(
        item => item.talkScriptId !== route.talkScriptId
      );
      newList.unshift(cleanObject(route));
      newList = newList.slice(0, maxNumOfHistories);
      commit('setHistory', newList);
      setItemToLocalStorage(localStorageKeyName, getters.getHistory);
    },
  },
};
