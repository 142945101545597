import * as dataResourceFactory from './dataResourceFactory';

class DataResource {
  getList(params) {
    return dataResourceFactory[params.resourceName].getList(params);
  }
  getListSync(params) {
    return dataResourceFactory[params.resourceName].getListSync(params);
  }
  getItem(params) {
    return dataResourceFactory[params.resourceName].getItem(params);
  }
  getItemSync(params) {
    return dataResourceFactory[params.resourceName].getItemSync(params);
  }
  ready(params) {
    return dataResourceFactory[params.resourceName].ready();
  }
  isEquals(a, b) {
    if (Array.isArray(a) && Array.isArray(b)) {
      return (
        a.length === b.length &&
        a.every((item, index) => this.isEquals(item, b[index]))
      );
    }
    return (
      a &&
      b &&
      a.resourceName === b.resourceName &&
      dataResourceFactory[a.resourceName].isEquals(a, b)
    );
  }
}

const dataResource = new DataResource();
export default dataResource;
