<template>
  <div class="loading-wrapper">
    <div v-if="isLoading">
      <div class="loadingArea">
        <img src="~@/resource/loading.svg" />
      </div>
    </div>
    <slot v-if="!isLoading" />
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import LoadingMixin from '@/mixins/LoadingMixin';

@Component({
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
})
export default class Loading extends mixins(LoadingMixin) {}
</script>

<style lang="scss" scoped>
.loading-wrapper {
  height: 100%;
  .loadingArea {
    z-index: 10000000;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 60px;
      height: 60px;
    }
  }
}
</style>
