<template>
  <div class="arrow">
    <div class="arrow__icon">
      <img src="~@/resource/sai-icon_scroll.gif" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class ScrollGuideContent extends Vue {}
</script>

<style lang="scss" scoped>
.arrow {
  &__icon {
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.5);
    width: 70px;
    height: 70px;
    padding: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -40px;
    margin-left: -40px;
  }
}
</style>
