<template>
  <transition :name="animation ? 'modal' : ''">
    <div class="sai-modal" v-if="show" @click.self="closeModal">
      <div class="inner">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
@Component({
  props: {
    click: Boolean,
    animation: Boolean,
    show: Boolean,
  },
})
export default class Modal extends Vue {
  closeModal() {
    if (this.click) {
      this.$emit('closeModal');
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/modal';
</style>
