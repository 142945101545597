import Vue from 'vue';
import Component from 'vue-class-component';
import dataResource from '@/common/dataResource';
import search from '@/common/tagSearch';
import { AsyncComputed } from '@/libs/vue-async-computed-decorator';
import textParser from '@/libs/textParser';

@Component({
  components: {},
  props: {
    list: Array,
    selectedTags: Array,
    inputTagEmpty: Boolean,
    isKeyHighlighted: {
      type: Boolean,
      default: false,
    },
  },
})
export default class ListContentMixin extends Vue {
  @AsyncComputed()
  async tagAndSearchKeys() {
    if (!this.isKeyHighlighted) {
      return [];
    }

    let keys = this.$store.getters['tagSearch/selectedKeywordTags'].map(t => {
      return t.displayText || t.text;
    });

    if (this.$store.state.tagSearch.searchText) {
      // Split search text by whitespace and ignore trailing whitespace
      let inputWords = this.$store.state.tagSearch.searchText.match(/\S+/g);
      if (inputWords) {
        keys.push(...inputWords);
      }
    }

    const synonymWords = await search.buildFullTextSearchQuery(keys);
    const allKeys = [].concat(...synonymWords);

    // Sort the keys array base on key length to make sure that longer words will take the precedence to be highlighted (e.g. tickets > ticket)
    allKeys.sort((a, b) => b.length - a.length);

    return allKeys;
  }

  async selectItem(item) {
    if (item.resourceName === 'scenario') {
      const scenario = await dataResource.getItem(item);
      Object.assign(item.items, scenario.items);
    }
    this.$emit('selected', item);
  }
  setShow(boolean) {
    this.$emit('setTagSearchShow', boolean);
  }
  cleanText(text) {
    return textParser.cleanText(text);
  }
  existsFaqNo(text) {
    return textParser.existsFaqNo(text);
  }
  generateFaqNo(text) {
    return textParser.generateFaqNo(text);
  }
}
