<template>
  <div
    class="FaqClientPage sai-pc"
    :class="{ chrome: isChrome() }"
    ref="faqClientTag"
  >
    <div class="sai-wrap" id="sai-top">
      <div class="sai-contentHeader" v-if="showDefaultHeader">
        <div class="section">
          <a class="logo" v-if="showHeaderIcon" @click.prevent="customClear()">
            <img
              :src="headerIconSrc(headerIconFilename)"
              :style="pcHeaderLogoHeight ? { height: pcHeaderLogoHeight } : {}"
            />
          </a>
          <div class="title" v-if="showHeaderTitle">
            <div class="text">{{ mainTitle }}</div>
          </div>
        </div>
        <div class="section" v-if="withInitialForm && userId">
          <div class="user_info">
            <div class="text">{{ userInfo }}</div>
          </div>
          <a class="logout" @click.prevent="removeUserId()">
            <font-awesome-icon icon="sign-out-alt" />
            <span>{{ logOutText }}</span>
          </a>
        </div>
      </div>
      <div class="sai-contentBody">
        <div class="sai-category" ref="category">
          <div class="sai-category__list">
            <ResourceTree
              :currentValue="{ resourceName: 'talkScript', talkScriptId: '#' }"
              :isRoot="true"
              :condition="item => item.resourceName === 'talkScript'"
              :showResult="showResult"
              :selectedCategories="selectedCategories"
              @setTagSearchShow="setTagSearchShow(true)"
            />
          </div>
        </div>
        <div class="sai-content" id="sai-tag-search-input">
          <div class="sai-content__wrap">
            <div class="sai-content__wrap__search">
              <div class="sai-content__wrap__search__header">
                <div class="sai-content__wrap__search__header__icon">
                  <img src="~@/resource/sai-icon_search.png" />
                </div>
                <div class="sai-content__wrap__search__header__title">
                  {{ searchInputTitle }}
                </div>
              </div>
              <div class="sai-content__wrap__search__content">
                <div class="sai-tags" ref="tags">
                  <div class="sai-tags__search">
                    <div class="sai-tags__search__inner">
                      <div ref="taggedInput">
                        <TaggedInput
                          :selectedTags="selectedTags.values()"
                          :searchText="searchText"
                          :filteredItems="filteredItems"
                          @onTagRemoved="removeSelectedTag"
                          @onSearchTextChanged="setSearchText"
                          @setShow="setTagSearchShow"
                        />
                      </div>
                      <transition name="fade">
                        <div
                          class="sai-tags__search__inner__list"
                          v-show="isAutocompleteShown"
                          :style="{
                            height: `calc(100vh - 145px - ${tagListTop}px)`,
                          }"
                        >
                          <div ref="tagList">
                            <PopularTagList
                              v-if="inputEmpty"
                              :tags="initialTags"
                              :tagTitle="tagPopularTitle"
                              :numberOfKeywordsDisplayed="
                                numberOfInitKeywordsDisplayed
                              "
                              @setShow="setTagSearchShow"
                            />
                            <TagList
                              v-if="!inputEmpty"
                              :candidateTags="candidateTags"
                              :tagListTop="tagListTop"
                              :searchText="searchText"
                              :inputEmpty="inputEmpty"
                              @onSelectTag="addSelectedTag"
                              @setShow="setTagSearchShow"
                            />
                          </div>
                          <div class="autocomplete_header">
                            <div class="title">{{ faqListTitle }}</div>
                            <div class="search_result" v-if="!inputEmpty">
                              <span class="count">
                                {{ filteredItems.length }}
                              </span>
                              <span class="text">
                                {{ candidatesCountSubText }}
                              </span>
                            </div>
                          </div>
                          <div
                            class="custom-list"
                            :style="{
                              height: `calc(100% - ${customListHeight() +
                                24}px)`,
                            }"
                          >
                            <template v-if="inputEmpty">
                              <Frequent @setTagSearchShow="setTagSearchShow" />
                            </template>
                            <template v-else>
                              <CategorySearch
                                v-if="filteredItems.length > 0"
                                :filteredItems="filteredItems"
                                @setTagSearchShow="setTagSearchShow"
                              />
                              <div v-else class="no_candidates_faq">
                                <div
                                  class="sai-caption"
                                  v-html="noCandidateFaqMessage"
                                ></div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </transition>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="sai-content__wrap__clear"
              id="sai-faq-top"
              ref="clearElements"
              v-show="showResult"
            >
              <a
                v-show="!inputEmpty"
                class="sai-content__wrap__clear__button"
                @click.prevent="backToSearch()"
              >
                <div class="sai-content__wrap__clear__button__icon chevron">
                  <font-awesome-icon icon="chevron-left" />
                </div>
                <span>{{ backToSearchButtonText }}</span>
              </a>
              <a
                class="sai-content__wrap__clear__button"
                @click.prevent="customClear()"
              >
                <div class="sai-content__wrap__clear__button__icon redo">
                  <font-awesome-icon icon="redo-alt" />
                </div>
                <span>{{ clearButtonTitle }}</span>
              </a>
            </div>
            <div
              class="sai-content__wrap__scenario"
              id="sai-scenario"
              ref="scenario"
              v-show="showResult"
            >
              <div class="sai-content__wrap__scenario__inner">
                <section
                  class="sai-content__wrap__scenario__inner__section question"
                  :class="{
                    active:
                      sections.talkScript.end === $store.state.navigation.index,
                    hasScenario: sections.scenario.length,
                  }"
                >
                  <div
                    class="sai-content__wrap__scenario__inner__section__head"
                    v-if="
                      $store.state.navigation.routes[sections.talkScript.end]
                    "
                  >
                    <div class="scenario_wrap">
                      <i
                        class="sai-content__wrap__scenario__inner__section__head__icon"
                      ></i>
                      <div
                        class="sai-content__wrap__scenario__inner__section__head__title"
                      >
                        <div class="ancestors">
                          <span
                            v-for="(ancester, index) in scriptAncesters"
                            :key="index"
                          >
                            {{ ancester.text }}
                            <span v-if="index < scriptAncesters.length - 1"
                              >/</span
                            >
                          </span>
                        </div>
                        <div class="question">
                          {{ cleanText(currentFaq.text) }}
                        </div>
                      </div>
                      <div
                        class="sai-content__wrap__scenario__inner__section__head__faq"
                        v-if="existsFaqNo(currentFaq.text)"
                      >
                        <div class="text">
                          <font-awesome-icon icon="tag" />
                          <span>{{ generateFaqNo(currentFaq.text) }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="sai-content__wrap__scenario__inner__section__content"
                  >
                    <VerticalNavigation
                      v-if="sections.scenario.length > 0"
                      :range="sections.scenario"
                    />
                    <section
                      class="sai-content__wrap__scenario__inner__section answer"
                      id="sai-scenario-answer"
                      v-if="sections.result.length"
                    >
                      <div
                        class="sai-content__wrap__scenario__inner__section__head"
                      >
                        <div class="scenario_wrap">
                          <i
                            class="sai-content__wrap__scenario__inner__section__head__icon"
                          ></i>
                          <div
                            class="sai-content__wrap__scenario__inner__section__head__title"
                          >
                            <div class="question">
                              {{ faqAnswerTitle }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="sai-content__wrap__scenario__inner__section__content"
                      >
                        <VerticalNavigation
                          v-if="sections.result.length > 0"
                          :range="sections.result"
                        />
                      </div>
                    </section>
                  </div>
                </section>
              </div>
            </div>
            <div
              class="sai-content__wrap__initial"
              v-show="!showResult"
              ref="listWrapper"
            >
              <template v-if="pcWithPopularTagsArea">
                <PopularTagList
                  :tags="initialTags"
                  :tagTitle="tagPopularTitle"
                  :numberOfKeywordsDisplayed="numberOfInitKeywordsDisplayed"
                  @setShow="setTagSearchShow"
                />
              </template>
              <template v-if="pcWithImageTagsArea">
                <TagCategory
                  v-for="imgTag in imageTags"
                  :key="imgTag.index"
                  :title="imgTag.title"
                  :tags="imgTag.tags"
                  @tagifyText="tagifyTextExact"
                  @setShow="setTagSearchShow"
                />
              </template>
              <template v-if="pcWithFrequentFaqArea">
                <div class="sai_faq">
                  <div class="title">
                    <div class="text">
                      {{ frequentTitle }}
                    </div>
                  </div>
                  <Frequent @setTagSearchShow="setTagSearchShow" />
                </div>
              </template>
              <template v-if="pcWithHistoryFaqArea">
                <div class="sai_faq">
                  <div class="title">
                    <div class="text">
                      {{ historyTitle }}
                    </div>
                  </div>
                  <History @setTagSearchShow="setTagSearchShow" />
                </div>
              </template>
            </div>
          </div>
          <template v-if="pcSciseedLink">
            <div class="bottom_icon">
              <a
                href="https://saichat.jp/saisearch/"
                target="_blank"
                rel="noopener"
              >
                <img src="@/resource/powered_by_sciseed.png" />
              </a>
            </div>
          </template>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
        class="sai-tag-search-modal"
        ref="modal"
        v-show="$store.state.tagSearch.show"
        @click.self="setTagSearchShow(false)"
      ></div>
    </transition>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import FaqClientTagMixin from '@/mixins/FaqClientTagMixin';

@Component({})
export default class FaqClientPage extends mixins(FaqClientTagMixin) {}
</script>

<style lang="scss" scoped>
@import '../style/component/faqClientPage';
</style>
