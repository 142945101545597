<template>
  <ListContent :list="list" @selected="selected" :isKeyHighlighted="true" />
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import { eventBus } from '@/eventBus';
import ListContentWrapperMixin from '@/mixins/ListContentWrapperMixin';

@Component({
  components: {},
  props: {
    filteredItems: Array,
  },
})
export default class CategorySearch extends mixins(ListContentWrapperMixin) {
  async selected(item) {
    await this.updateRoutes(item);
    this.$emit('setTagSearchShow', false);
    setTimeout(() => {
      eventBus.$emit('scrollToFaqTitle');
    }, 100);

    this.$store.commit('ticket/setResetFlag', true);
    const categoryTags = this.$store.getters['tagSearch/selectedCategoryTags'];
    const faqAction = categoryTags.length > 0 ? 'category' : 'search';
    const newTicket = await this.updateTicket({
      item: item,
      faqAction: faqAction,
    });
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  get list() {
    return this.filteredItems;
  }
}
</script>
